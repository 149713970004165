<template>
  <div class="tabs">
    <el-tag
      v-for="tag in tags"
      :key="tag.name"
      class="mx-1"
      closable
      :disable-transitions="false"
      @close="closeTag(tag)"
      @click="clickTag(tag)"
      :effect="$route.name === tag.name ? 'dark' : 'plain'"
    >
      {{ tag.label }}
    </el-tag>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      tags: (state) => state.Tab.menuList,
    }),
    ...mapState({
      isCollapse: (state) => state.Tab.isCollapse,
      currentMenu: (state) => state.Tab.currentMenu,
    }),
  },
  methods: {
    closeTag(tag) {
      this.$store.commit("CLOSE_TAB", tag);
      console.log(this.$route.name);
      if (this.currentMenu.name === tag.name) {
        this.$store.commit("RESET_CURRENT_MENU");
        this.$router.push({ name: "home" });
      }
    },
    clickTag(tag) {
      console.log(tag.path.toString().split("/")[1]);
      this.$router.push({ name: tag.name });
      this.$store.commit("SELECT_MENU", tag);
    },
  },
};
</script>

<style lang="scss">
.tabs {
  padding: 20px;
  .el-tag {
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>
