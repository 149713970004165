import {
  accountInfoApi,
  loginApi,
  registerApi,
  sendCodeApi,
  uploadImgApi,
} from "../../api/getData";

const Account = {
  state: () => ({
    token: localStorage.getItem("token") || "",
    sendCodeStatus: localStorage.getItem("sendCodeStatus") === "TRUE" || false,
    registerStatus: localStorage.getItem("registerStatus") === "TRUE" || false,
    loginStatus: localStorage.getItem("loginStatus") === "TRUE" || false,
    logonDisabled: localStorage.getItem("logonDisabled") || "LOCK",
    sendCodeType: "",
    accountTo: "",
    loginPhone: "",
    loginMail: "",
    accountInfo: JSON.parse(localStorage.getItem("accountInfo")) || {
      accountNo: "",
      headImg: "",
      phone: "",
      mail: "",
      username: "",
      auth: "",
      createTime: "",
    },
  }),
  mutations: {
    LOGON(state) {
      localStorage.setItem("token", "");
      localStorage.setItem("loginStatus", "FALSE");
      localStorage.setItem("sendCodeStatus", "FALSE");
      localStorage.setItem("registerStatus", "FALSE");
      localStorage.setItem("accountInfo", JSON.stringify(""));
      state.token = "";
      state.loginStatus = false;
      state.sendCodeStatus = false;
      state.registerStatus = false;
      state.accountInfo = {};
      state.loginPhone = "";
      state.headImg = "";
    },
    LOGON_SPAN(state, disabled) {
      localStorage.setItem("logonDisabled", disabled);
      state.logonDisabled = disabled;
    },
    SEND_CODE_SUCCESS(state, accountTo) {
      state.sendCodeStatus = true;
      localStorage.setItem("sendCodeStatus", "TRUE");
      state.accountTo = accountTo;
      if (accountTo.toString().split("@").length === 2) {
        state.sendCodeType = "MAIL";
      } else {
        state.sendCodeType = "PHONE";
      }
    },
    SEND_CODE_FAILURE(state) {
      state.sendCodeStatus = false;
    },
    REGISTER_SUCCESS(state) {
      state.registerStatus = true;
      localStorage.setItem("sendCodeStatus", "TRUE");
    },
    REGISTER_FAILURE(state) {
      state.registerStatus = false;
    },
    UPLOAD_IMG(state, headImg) {
      state.headImg = headImg;
    },
    LOGIN_SUCCESS(state, token) {
      state.loginStatus = true;
      localStorage.setItem("token", token);
      localStorage.setItem("loginStatus", "TRUE");
      state.token = token;
    },
    LOGIN_FAILURE(state) {
      state.loginStatus = false;
    },
    ACCOUNT_INFO_SUCCESS(state, accountInfo) {
      state.accountInfo.accountNo = accountInfo.accountNo;
      state.accountInfo.headImg = accountInfo.headImg;
      if (accountInfo.phone === null) {
        state.accountInfo.phone = "";
      } else {
        state.accountInfo.phone = accountInfo.phone;
      }
      if (accountInfo.mail === null) {
        state.accountInfo.mail = "";
      } else {
        state.accountInfo.mail = accountInfo.mail;
      }
      state.accountInfo.username = accountInfo.username;
      if (accountInfo.auth === "DEFAULT" || accountInfo.auth === "FIRST") {
        state.accountInfo.auth = "青铜会员";
      } else if (accountInfo.auth === "SECOND") {
        state.accountInfo.auth = "黄金会员";
      } else if (accountInfo.auth === "THIRD") {
        state.accountInfo.auth = "黑金会员";
      }
      state.accountInfo.createTime = accountInfo.gmtCreate;
      localStorage.setItem("accountInfo", JSON.stringify(state.accountInfo));
    },
  },
  actions: {
    async sendCode({ commit }, account) {
      let result = await sendCodeApi(account.to, account.captcha);
      if (result.data.code === 0) {
        commit("SEND_CODE_SUCCESS", account.to);
      } else {
        commit("SEND_CODE_FAILURE");
      }
    },
    async uploadImg({ commit }, uploadData) {
      let result = await uploadImgApi(uploadData);
      if (result.data.code === 0) {
        commit("UPLOAD_IMG", result.data.data);
      }
    },
    async register({ commit }, register) {
      let result = await registerApi(
        register.code,
        register.headImg,
        register.username,
        register.phone,
        register.mail,
        register.pwd
      );
      if (result.data.code === 0) {
        commit("REGISTER_SUCCESS");
      } else {
        commit("REGISTER_FAILURE");
      }
    },
    async login({ commit, state }, login) {
      if (login.phoneOrEmail.toString().split("@").length === 2) {
        state.loginMail = login.phoneOrEmail;
      } else {
        state.loginPhone = login.phoneOrEmail;
      }
      let result = await loginApi(state.loginPhone, state.loginMail, login.pwd);
      if (result.data.code === 0) {
        commit("LOGIN_SUCCESS", result.data.data);
      } else {
        commit("LOGIN_FAILURE");
      }
    },
    async accountInfo({ commit, state }) {
      let result = await accountInfoApi(state.token);
      if (result.data.code === 0) {
        commit("ACCOUNT_INFO_SUCCESS", result.data.data);
      }
    },
  },
  getters: {},
};
export default Account;
