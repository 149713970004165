import { payApi } from "../../api/getData";

const pageRecord = {
  state: () => ({
    productId: 0,
    buyNum: 1,
    couponId: 0,
    callbackInfo: "",
    productPriceList: [
      {
        productId: 0,
        productPrice: 0.0,
      },
      {
        productId: 1,
        productPrice: 0.01,
      },
      {
        productId: 2,
        productPrice: 0.01,
      },
      {
        productId: 3,
        productPrice: 0.01,
      },
    ],
  }),
  mutations: {
    CREATE_WECHAT_PAY(state, data) {
      state.callbackInfo = data;
    },
    CREATE_ALIPAY(state, data) {
      state.callbackInfo = data;
    },
    SET_BUY_NUM(state, buyNum) {
      state.buyNum = buyNum;
    },
    SET_BUY_PRODUCT_ID(state, productId) {
      state.productId = productId;
    },
    SET_BUY_COUPON_ID(state, couponId) {
      state.couponId = couponId;
    },
  },
  actions: {
    async createPay({ commit }, payInfo) {
      let result = await payApi(
        localStorage.getItem("token"),
        payInfo.productId,
        payInfo.buyNum,
        payInfo.totalAmount,
        payInfo.payAmount,
        payInfo.payType,
        payInfo.billType,
        payInfo.clientType,
        payInfo.billHeader,
        payInfo.billContent,
        payInfo.billReceiverPhone,
        payInfo.billReceiverEmail
      );
      if (result.data.code === 0) {
        commit("CREATE_WECHAT_PAY", result.data.data.code_url);
      } else {
        commit("CREATE_ALIPAY", result.data);
      }
    },
  },
  getters: {},
};

export default pageRecord;
