import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/reset.scss";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// 配置拦截，拦截路径requiresAuth=true的路径
router.beforeEach((to, from, next) => {
  // 从本地拿到token
  const token = localStorage.getItem("token");

  // 如果requiresAuth=true，进行拦截否则放行
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 如果token不为空，放行，否则拦截
    if (token) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

createApp(App).use(store).use(router).use(ElementPlus).mount("#app");
