<template>
  <header>
    <div class="l-content">
      <el-button plain @click="changeCollapse">
        <el-icon :size="22">
          <MMenu />
        </el-icon>
      </el-button>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item
          :to="{ path: '/' }"
          v-if="current === null || current.name"
          >首页
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="current.path" v-if="current" class="l-hover"
          >{{ current.label }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <img
            :src="this.headImg || userInfo.defaultUserImage"
            alt=""
            class="user"
          />
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <span
                @click="personal"
                v-loading.fullscreen.lock="personalLoading"
                >个人中心</span
              >
            </el-dropdown-item>
            <el-dropdown-item :disabled="this.logonIsLock === 'LOCK'">
              <span @click="logon" v-loading.fullscreen.lock="logonLoading"
                >退&ensp;&ensp;&ensp;出</span
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import { Menu as MMenu } from "@element-plus/icons-vue";
import { mapState } from "vuex";

export default {
  components: {
    MMenu,
  },
  computed: {
    ...mapState({
      current: (state) => state.Tab.currentMenu,
      logonIsLock: (state) => state.Account.logonDisabled,
      headImg: (state) => state.Account.accountInfo.headImg,
    }),
  },
  data() {
    return {
      userInfo: {
        userImg: "",
        defaultUserImage: require("../assets/images/cl.png"),
      },
      logonLoading: false,
      personalLoading: false,
    };
  },
  methods: {
    logon() {
      if (this.logonIsLock === "UNLOCK") {
        this.$store.commit("LOGON");
        this.logonLoading = true;
        setTimeout(() => {
          this.logonLoading = false;
          this.$store.commit("LOGON_SPAN", "LOCK");
          this.$router.push({ name: "login" });
        }, 300);
      }
    },
    personal() {
      this.personalLoading = true;
      setTimeout(() => {
        this.personalLoading = false;
        this.$router.push({ name: "accountInfo" });
      }, 100);
    },
    changeCollapse() {
      this.$store.commit("CHANGE_COLLAPSE");
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.l-content {
  display: flex;
  align-items: center;

  .el-button {
    margin-right: 20px;
  }
  .l-hover {
    cursor: pointer;
  }
}

.r-content {
  .user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>

<style lang="scss" scoped>
.el-breadcrumb__item {
  .el-breadcrumb__inner {
    color: #666666;
    font-weight: normal;
  }

  &:last-child {
    .el-breadcrumb__inner {
      color: #ffffff;
    }
  }
}
</style>
