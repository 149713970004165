<template>
  <el-menu
    default-active="/"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    background-color="#DCDFE6"
  >
    <el-menu-item index="1" style="color: black">
      <el-icon>
        <Platform />
      </el-icon>
      <template #title><span>&ensp;&ensp;cl&ensp;短链平台</span></template>
    </el-menu-item>
    <el-menu-item
      :index="item.path"
      v-for="item in asideHouseAndVideoMenu"
      :key="item.path"
      @click="clickMenu(item)"
    >
      <el-icon v-if="item.icon === 'VideoPlay'">
        <VideoPlay />
      </el-icon>
      <el-icon v-if="item.icon === 'House'">
        <House />
      </el-icon>
      <span>&ensp;&ensp;{{ item.label }}</span>
    </el-menu-item>
    <el-sub-menu
      :index="item.children[0].path"
      v-for="item in asideUserMenu"
      :key="item.children[0].path"
    >
      <template #title>
        <el-icon v-if="item.icon === 'Avatar'">
          <Avatar />
        </el-icon>
        <span>&ensp;&ensp;{{ item.label }}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item
          :index="subItem.path"
          v-for="subItem in item.children"
          :key="subItem.path"
          @click="clickMenu(subItem)"
          :disabled="isLock(subItem)"
        >
          <el-icon v-if="subItem.icon === 'Setting'">
            <Setting />
          </el-icon>
          <el-icon v-if="subItem.icon === 'Stamp'">
            <Stamp />
          </el-icon>
          <span>&ensp;&ensp;{{ subItem.label }}</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
  </el-menu>
</template>

<script>
import {
  House,
  VideoPlay,
  Avatar,
  Setting,
  Platform,
  Stamp,
} from "@element-plus/icons-vue";
import { mapState } from "vuex";

export default {
  components: {
    House,
    Platform,
    VideoPlay,
    Avatar,
    Setting,
    Stamp,
  },
  data() {
    return {
      asideHouseAndVideoMenu: [
        {
          path: "/",
          name: "home",
          label: "首页",
          icon: "House",
        },
      ],
      asideUserMenu: [
        {
          label: "用户管理",
          icon: "Avatar",
          children: [
            {
              path: "/account/register/send_code",
              name: "sendCode",
              label: "用户注册",
              icon: "Stamp",
            },
            {
              path: "/account/login",
              name: "login",
              label: "用户登录",
              icon: "Stamp",
            },
            {
              path: "/account/info",
              name: "accountInfo",
              label: "个人信息",
              icon: "Setting",
            },
          ],
        },
        {
          label: "流量包购买",
          icon: "Avatar",
          children: [
            {
              path: "/pay",
              name: "pay",
              label: "下单支付",
              icon: "Stamp",
            },
            {
              path: "/account/traffic",
              name: "accountTraffic",
              label: "已购流量包",
              icon: "Stamp",
            },
            {
              path: "/order",
              name: "order",
              label: "订单管理",
              icon: "Setting",
            },
          ],
        },
        {
          label: "短链管理",
          icon: "Avatar",
          children: [
            {
              path: "/link/create",
              name: "createLink",
              label: "创建短链",
              icon: "Stamp",
            },
            {
              path: "/link",
              name: "myLink",
              label: "个人短链",
              icon: "Stamp",
            },
            {
              path: "/link/info",
              name: "linkInfo",
              label: "短链信息",
              icon: "Setting",
            },
          ],
        },
      ],
      isDisabled: false,
    };
  },
  methods: {
    clickMenu(menu) {
      this.$store.commit("SELECT_MENU", menu);
      this.$router.push({ name: menu.name });
      this.$store.commit("SELECT_MENU", menu);
    },
    isLock(subItem) {
      if (subItem.name === "sendCode" || subItem.name === "login") {
        if (this.loginStatus) {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.Tab.isCollapse,
      loginStatus: (state) => state.Account.loginStatus,
    }),
  },
};
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 185px;
  min-height: 100%;
}
</style>
