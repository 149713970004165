const Tab = {
  state: () => ({
    isCollapse: false,
    menus: [],
    currentMenu: JSON.parse(localStorage.getItem("currentMenu")) || null,
    menuList: JSON.parse(localStorage.getItem("menuList")) || [
      {
        path: "/",
        name: "home",
        label: "首页",
        icon: "House",
        contain: "home",
      },
    ],
  }),
  mutations: {
    CLOSE_TAB(state, menu) {
      let indexOfMenu = state.menuList.findIndex(
        (item) => item.name === menu.name
      );
      if (indexOfMenu !== -1 && menu.name !== "home") {
        state.menuList.splice(indexOfMenu, 1);
        localStorage.setItem("menuList", JSON.stringify(state.menuList));
      }
    },
    SELECT_MENU(state, menu) {
      if (menu.name !== "home") {
        if (state.menuList.length <= 6) {
          state.currentMenu = menu;
          localStorage.setItem(
            "currentMenu",
            JSON.stringify(state.currentMenu)
          );
          let isExit = state.menuList.findIndex(
            (item) => item.name === menu.name
          );
          if (isExit === -1) {
            state.menuList.push(menu);
            localStorage.setItem(
              "currentMenu",
              JSON.stringify(state.currentMenu)
            );
            localStorage.setItem("menuList", JSON.stringify(state.menuList));
          }
        } else {
          let indexOfMenu = state.menuList.findIndex(
            (item) => item.name === menu.name
          );
          if (indexOfMenu !== -1 && menu.name !== "home") {
            state.menuList.splice(1, 1);
            localStorage.setItem("menuList", JSON.stringify(state.menuList));
          }
        }
      } else {
        state.currentMenu = null;
      }
    },
    CHANGE_COLLAPSE(state) {
      if (state.isCollapse) {
        state.isCollapse = false;
      } else if (!state.isCollapse) {
        state.isCollapse = true;
      }
    },
    RESET_CURRENT_MENU(state) {
      state.currentMenu = null;
    },
  },
  actions: {},
  getters: {},
};
export default Tab;
