import { createRouter, createWebHashHistory } from "vue-router";
import Main from "../views/Main";

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Home/Home"),
      },
      {
        path: "/video",
        name: "video",
        component: () => import("../views/VideoManager/Video"),
        meta: { requiresAuth: true },
      },
      {
        path: "/account/register/send_code",
        name: "sendCode",
        component: () => import("../views/AccountManager/SendCode"),
      },
      {
        path: "/account/register",
        name: "register",
        component: () => import("../views/AccountManager/Register"),
      },
      {
        path: "/account/login",
        name: "login",
        component: () => import("../views/AccountManager/Login"),
      },
      {
        path: "/account/info",
        name: "accountInfo",
        component: () => import("../views/AccountManager/AccountInfo"),
        meta: { requiresAuth: true },
      },
      {
        path: "/pay",
        name: "pay",
        component: () => import("../views/Pay/Pay"),
        meta: { requiresAuth: true },
      },
      {
        path: "/account/traffic",
        name: "accountTraffic",
        component: () => import("../views/Pay/AccountTraffic"),
        meta: { requiresAuth: true },
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/Pay/Order"),
        meta: { requiresAuth: true },
      },
      {
        path: "/pay/wechat_qr",
        name: "wechatQr",
        component: () => import("../views/Pay/WechatQr"),
        meta: { requiresAuth: true },
      },
      {
        path: "/link/create",
        name: "createLink",
        component: () => import("../views/Link/CreateLink"),
        meta: { requiresAuth: true },
      },
      {
        path: "/link",
        name: "myLink",
        component: () => import("../views/Link/MyLink"),
        meta: { requiresAuth: true },
      },
      {
        path: "/link/info",
        name: "linkInfo",
        component: () => import("../views/Link/LinkInfo"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
