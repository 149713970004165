import { pageRecordApi } from "../../api/getData";

const pageRecord = {
  state: () => ({
    code: localStorage.getItem("code") || "01Tzoa2a",
    pageSize: localStorage.getItem("pageSize") - 0 || 10,
    pageRecordList: [],
    total: 0,
    totalPage: 0,
    currentPage: localStorage.getItem("currentPage") - 0 || 1,
  }),
  mutations: {
    SET_PAGE_RECORD(state, data) {
      state.pageRecordList = data.current_page_data;
      state.total = data.total;
      state.totalPage = data.total_page;
      state.currentPage = data.current_page;
    },
    CHANGE_CURRENT_PAGE(state, changePage) {
      state.currentPage = changePage;
    },
    CHANGE_SIZE(state, changeSize) {
      state.size = changeSize;
    },
  },
  actions: {
    async setPageRecord({ commit, state }) {
      let result = await pageRecordApi(
        localStorage.getItem("token"),
        state.code,
        state.currentPage,
        state.pageSize
      );
      if (result.data.code === 0) {
        commit("SET_PAGE_RECORD", result.data.data);
      }
    },
    changeCurrentPage({ commit }, changePage) {
      localStorage.setItem("currentPage", changePage);
      commit("CHANGE_CURRENT_PAGE", changePage);
      location.reload();
    },
    changeSize({ commit }, changeSize) {
      localStorage.setItem("pageSize", changeSize);
      commit("CHANGE_SIZE", changeSize);
      location.reload();
    },
  },
  getters: {},
};

export default pageRecord;
