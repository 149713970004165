import axios from "../Request";

/**
 * 分页查询---不限日期
 * @param token token
 * @param code code
 * @param page page
 * @param size size
 */
export const pageRecordApi = (token, code, page, size) =>
  axios
    .post(
      "data-server/api/visit_stats/v1/page_record",
      {
        code: code,
        page: page,
        size: size,
      },
      {
        headers: {
          token: token,
        },
      }
    )
    .then();

export const payApi = (
  token,
  productId,
  buyNum,
  totalAmount,
  payAmount,
  payType,
  billType,
  clientType,
  billHeader,
  billContent,
  billReceiverPhone,
  billReceiverEmail
) =>
  axios
    .post(
      "shop-server/api/order/v1/confirm",
      {
        product_id: productId,
        buy_num: buyNum,
        total_amount: totalAmount,
        pay_amount: payAmount,
        pay_type: payType,
        bill_type: billType,
        client_type: clientType,
        bill_header: billHeader,
        bill_content: billContent,
        bill_receiver_phone: billReceiverPhone,
        bill_receiver_email: billReceiverEmail,
      },
      {
        headers: {
          token: token,
        },
      }
    )
    .then();

export const sendCodeApi = (to, captcha) =>
  axios
    .post(
      "account-server/api/notify/v1/send_code",
      {
        to: to,
        captcha: captcha,
      },
      {
        headers: {},
      }
    )
    .then();

export const uploadImgApi = (uploadData) =>
  axios
    .post("account-server/api/account/v1/upload_img", uploadData, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .then();

export const registerApi = (code, headImg, username, phone, mail, pwd) =>
  axios
    .post(
      "account-server/api/account/v1/register",
      {
        code: code,
        head_img: headImg,
        username: username,
        phone: phone,
        mail: mail,
        pwd: pwd,
      },
      {
        headers: {},
      }
    )
    .then();

export const loginApi = (phone, mail, password) =>
  axios
    .post(
      "account-server/api/account/v1/login",
      {
        phone: phone,
        mail: mail,
        pwd: password,
      },
      {
        headers: {},
      }
    )
    .then();

export const createLinkApi = (
  token,
  groupId,
  title,
  originalUrl,
  domainId,
  domainType,
  expired
) =>
  axios
    .post(
      "link-server/api/link/v1/add",
      {
        group_id: groupId,
        title: title,
        original_url: originalUrl,
        domain_id: domainId,
        domain_type: domainType,
        expired: expired,
      },
      {
        headers: {
          token: token,
        },
      }
    )
    .then();

export const accountInfoApi = (token) =>
  axios
    .get("account-server/api/account/v1/detail", {
      headers: {
        token: token,
      },
    })
    .then();
