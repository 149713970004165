<template>
  <div class="common-layout" style="height: 100%">
    <el-container style="height: 100%">
      <el-aside width="auto">
        <c-aside />
      </el-aside>
      <el-container>
        <el-header>
          <c-header />
        </el-header>
        <c-tab />
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CAside from "../components/CommonAside";
import CHeader from "../components/CommonHeader";
import CTab from "../components/CommonTab";

export default {
  components: {
    CAside,
    CHeader,
    CTab,
  },
};
</script>

<style lang="scss" scoped>
.el-header {
  background-color: #dcdfe6;
}
</style>
