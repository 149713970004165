import { createLinkApi } from "../../api/getData";

const Link = {
  state: () => ({
    createLinkStatus: false,
  }),
  mutations: {
    CREATE_LINK_SUCCESS(state) {
      state.createLinkStatus = true;
    },
    CREATE_LINK_FAILURE(state) {
      state.createLinkStatus = false;
    },
  },
  actions: {
    async createLink({ commit }, linkInfo) {
      if (linkInfo.expiredTime === "" && linkInfo.expiredDay === "") {
        linkInfo.expired = -1;
      } else if (linkInfo.setDay !== "" && linkInfo.setTime !== "") {
        linkInfo.expired = linkInfo.setDay + linkInfo.setTime;
      } else {
        linkInfo.expired = "";
      }
      let result = await createLinkApi(
        localStorage.getItem("token"),
        linkInfo.groupId,
        linkInfo.title,
        linkInfo.originalUrl,
        linkInfo.domainId,
        linkInfo.domainType,
        linkInfo.expired
      );
      console.log(result);
      if (result.data.code === 0) {
        commit("CREATE_LINK_SUCCESS");
      } else {
        commit("CREATE_LINK_FAILURE");
      }
    },
  },
  getters: {},
};
export default Link;
