import { createStore } from "vuex";
import Tab from "../store/module/Tab";
import Data from "../store/module/Data";
import Pay from "../store/module/Pay";
import Account from "../store/module/Account";
import Link from "../store/module/Link";

export default createStore({
  modules: {
    Tab,
    Data,
    Pay,
    Account,
    Link,
  },
});
